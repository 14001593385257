
.wishlist-page {
  .map-container {
    height: 400px;
    margin: 20px 0;
  }

  .village-row {
    border-top: solid 1px #aaa;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
