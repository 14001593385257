@import "bootstrap";
@import "fonts";

html, body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
}

nav.navbar-dark {
  background-color: darkgreen;
  color: white;

  .navbar-brand {
    color: white;
    font-size: 20px;
  }

  .navbar-nav .nav-link {
    color: white;
    font-size: 20px;
  }
}

h1, h3 {
  color: #024302;
  padding-top: 20px;
}


.wrapper {
  min-height: 100%;
  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -260px;
}
footer, .push {
  height: 260px
}

@media (max-width: 767px) {
  .wrapper {
    margin-bottom: -644px;
  }
  #push, footer {
    height: 644px;
  }
}
