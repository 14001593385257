.country-details-page {
  .country-header {
    padding-bottom: 20px;
  }
  .village-row {
    border-top: solid 1px #aaa;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
