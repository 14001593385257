
footer {
  background-color: #024302;
  div, span, a, .btn-link {
    color: white;
  }
  h3 {
    color: #fff;
    font-family: Roboto,sans-serif;
    font-weight: 700;
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    font-size: 16px;
    margin: 24px 0 14px;
  }
  .linkSet {
    list-style-type: none;
    padding-inline-start: 0;
    width: 100%;

    li {
      border-top: 1px solid #4f4f4f;
      padding: 4px 0;
      font-size: 12px;
      list-style-type: none;
      color: #a5a5a5;

      a, .btn-link {
        color: #cecece;
        font-family: Roboto,sans-serif;
        font-weight: 300;
        font-size: 15px;
        padding: 0;
      }
    }
  }

  .footer__settings {
    border-top: 1px solid #4f4f4f;
  }

  .footer__subscribe {
    margin-bottom: 20px;
  }

  .footer__currency {
    .navbar {
      margin-bottom: 0;
    }
  }

  .footer__language {
    padding: 16px 0 6px;

    span {
      background: transparent url(https://tilpy.com/img/languages-flag_sprite.png) no-repeat 0 0;
      display: inline-block;
      height: 16px;
      width: 22px;
      text-indent: -9999px;
      padding: 0;
      margin: 0 12px 0 0;
      border: 0;

      &.language__en {
        background-position: 0 -16px;
      }
      &.language__de {
        background-position: 0 -64px;
      }
      &.language__ru {
        background-position: 0 0;
      }
    }
  }
}

@media (max-width: 767px) {

}
