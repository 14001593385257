// import GoogleFonts: Roboto
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,700,500);

/*
ROBOTO:
Thin 100
Light 300
Normal 400
Medium 500
Bold 700
*/

@font-face {
	font-weight: normal;
	font-style: normal;
}

@mixin univers-thin() {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    //text-rendering: optimizeLegibility !important;
    //-webkit-font-smoothing: antialiased !important;
}
@mixin univers-light() {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    //text-rendering: optimizeLegibility !important;
    //-webkit-font-smoothing: antialiased !important;
}
@mixin univers-roman() {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}
@mixin univers-medium() {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}
@mixin univers-bold() {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

strong {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}
